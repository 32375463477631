var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [
      _c("img", { attrs: { src: _vm.$auth.user.picture } }),
      _c("h2", [_vm._v(_vm._s(_vm.$auth.user.name))]),
      _c("p", [_vm._v(_vm._s(_vm.$auth.user.email))])
    ]),
    _c("div", [
      _c("pre", [_vm._v(_vm._s(JSON.stringify(_vm.$auth.user, null, 2)))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }